import en from "../locales/en.json";
import fr from "../locales/fr.json";
import nl from "../locales/nl.json";

export default (key, locale) => {
  switch (locale) {
    case "en":
      return en[key];
    case "nl":
      return nl[key];
    default:
      return fr[key];
  }
};
