import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/mail.svg";
import getLocaleString from "utils/getLocaleString";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

const ContactUsForm = ({ locale }) => {
  const subheading = getLocaleString("ContactSubHeading", locale),
    heading = (
      <>
        {getLocaleString("ContactTitle1", locale)}
        <HighlightedText>
          {getLocaleString("ContactTitle2", locale)}
        </HighlightedText>
        <wbr />
        {getLocaleString("ContactTitle3", locale)}
      </>
    ),
    description = (
      <>
        <p>
          Tel:{" "}
          <a tw="text-primary-500" href="tel:+32475265534">
            +32 475 26 55 34
          </a>
        </p>
        <p>
          Email:{" "}
          <a tw="text-primary-500" href="mailto:info@cleanfloorsconcept.be">
            info@cleanfloorsconcept.be
          </a>
        </p>
      </>
    ),
    textOnLeft = true;

  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [sujet, setSujet] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const body = {
      nom,
      email,
      sujet,
      message,
    };

    toast
      .promise(
        fetch(
          "https://script.google.com/macros/s/AKfycbyBTDZ4mRpECsJNAXX1CVNzhdaRadGrZhk0nwOCE1QwmvqVMrhpzaGZ71z6xtLeBOhmOg/exec",
          {
            redirect: "follow",
            method: "POST",
            body: JSON.stringify(body),
          }
        ),
        {
          pending: getLocaleString("SendingMail", locale),
          success: getLocaleString("MailSent", locale),
          error: getLocaleString("MailError", locale),
        }
      )
      .finally(() => {
        setNom("");
        setEmail("");
        setSujet("");
        setMessage("");
        setIsSubmitting(false);
      });
  };

  return (
    <Container id="contact">
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
              <Input
                type="email"
                name="email"
                placeholder={getLocaleString("YourEmailAddress", locale)}
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                type="text"
                name="nom"
                placeholder={getLocaleString("Name", locale)}
                minLength="4"
                required
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
              <Input
                type="text"
                name="sujet"
                placeholder={getLocaleString("Subject", locale)}
                minLength="4"
                required
                value={sujet}
                onChange={(e) => setSujet(e.target.value)}
              />
              <Textarea
                name="message"
                placeholder={getLocaleString("YourMessageHere", locale)}
                minLength="10"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <SubmitButton type="submit" disabled={isSubmitting}>
                {getLocaleString("Send", locale)}
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default ContactUsForm;
