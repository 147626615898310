import React from "react";
import tw from "twin.macro";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo.png";
import getLocaleString from "utils/getLocaleString";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default ({ locale }) => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg
              src={logo}
              style={{ width: 150, borderRadius: 300 }}
              alt="Logo SooShine"
            />
          </LogoContainer>
          <LinksContainer>
            <Link href="#">{getLocaleString("Home", locale)}</Link>
            <Link href="#info">{getLocaleString("Information", locale)}</Link>
            <Link href="#services">
              {getLocaleString("OurServices", locale)}
            </Link>
            <Link href="#contact">{getLocaleString("Contact", locale)}</Link>
          </LinksContainer>
          <CopyrightText>
            &copy; Copyright 2023, Clean Floors Concept{" "}
            {getLocaleString("AllRightsReserved", locale)}.<br />
            <a href="https://storyset.com/business">
              Business illustrations by Storyset
            </a>
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
