import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import marble from "images/marble.jpg";
import thermoplastic from "images/thermoplastic.jpg";
import wood from "images/wood.jpg";
import carpet from "images/carpet.jpg";
import parking from "images/parking.jpg";
import getLocaleString from "utils/getLocaleString";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-screen-md`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({ locale }) => {
  const cards = [
    {
      imageSrc: marble,
      title: (
        <>
          {getLocaleString("Card1Title1", locale)}
          <HighlightedText>
            {getLocaleString("Card1Title2", locale)}
          </HighlightedText>
          {getLocaleString("Card1Title3", locale)}
        </>
      ),
      description: getLocaleString("Card1Description", locale),
    },

    {
      imageSrc: thermoplastic,
      title: (
        <>
          {getLocaleString("Card2Title1", locale)}
          <HighlightedText>
            {getLocaleString("Card2Title2", locale)}
          </HighlightedText>
          {getLocaleString("Card2Title3", locale)}
        </>
      ),
      description: getLocaleString("Card2Description", locale),
    },

    {
      imageSrc: wood,
      title: (
        <>
          {getLocaleString("Card3Title1", locale)}
          <HighlightedText>
            {getLocaleString("Card3Title2", locale)}
          </HighlightedText>
          {getLocaleString("Card3Title3", locale)}
        </>
      ),
      description: getLocaleString("Card3Description", locale),
    },
    {
      imageSrc: carpet,
      title: (
        <>
          {getLocaleString("Card4Title1", locale)}
          <HighlightedText>
            {getLocaleString("Card4Title2", locale)}
          </HighlightedText>
          {getLocaleString("Card4Title3", locale)}
        </>
      ),
      description: getLocaleString("Card4Description", locale),
    },
    {
      imageSrc: parking,
      title: (
        <>
          {getLocaleString("Card5Title1", locale)}
          <HighlightedText>
            {getLocaleString("Card5Title2", locale)}
          </HighlightedText>
          {getLocaleString("Card5Title3", locale)}
        </>
      ),
      description: getLocaleString("Card5Description", locale),
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>
            {getLocaleString("FeatureHeading1", locale)}
            <HighlightedText>
              {getLocaleString("FeatureHeading2", locale)}
            </HighlightedText>
            {getLocaleString("FeatureHeading3", locale)}
          </HeadingTitle>
          <HeadingDescription>
            {getLocaleString("FeatureDescription", locale)}
          </HeadingDescription>
          <HeadingDescription>
            {getLocaleString("FeatureDescription2", locale)}
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
