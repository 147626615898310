import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import ReactFlagsSelect from "react-flags-select";
import heroImage from "images/hero.jpg";
import getLocaleString from "utils/getLocaleString.js";

import Header, {
  NavLink,
  NavLinks,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url(${heroImage});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

const navigateToInfo = () => {
  window.scrollBy(0, (window.outerHeight + 400) * 0.68);
};

const navigateToContact = () => {
  window.scrollBy(0, document.body.scrollHeight * 0.78);
};

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default ({ selected, setSelected, locale }) => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#">{getLocaleString("Home", locale)}</NavLink>
      <NavLink className="pointer" onClick={navigateToInfo}>
        {getLocaleString("OurServices", locale)}
      </NavLink>
      <NavLink className="pointer" onClick={navigateToContact}>
        {getLocaleString("Contact", locale)}
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <ReactFlagsSelect
        countries={["FR", "NL", "US"]}
        customLabels={{ FR: "Français", NL: "Nederlands", US: "English" }}
        selected={selected}
        onSelect={(code) => {
          setSelected(code);
        }}
      />
    </NavLinks>,
  ];

  return (
    <Container className="heroContainer">
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
            {getLocaleString("HeroTitle1", locale)}
            <HighlightedText>
              {getLocaleString("HeroTitle2", locale)}
            </HighlightedText>
            <br />
            {getLocaleString("HeroTitle3", locale)}
          </Heading>

          <PrimaryAction onClick={navigateToInfo}>
            {getLocaleString("MoreInformation", locale)}
          </PrimaryAction>
        </Content>
      </HeroContainer>
    </Container>
  );
};
