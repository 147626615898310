import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/MiniCenteredFooter";
import "react-toastify/dist/ReactToastify.css";
const getLocaleFromCountry = (country) => {
  switch (country) {
    case "US":
      return "en";
    case "NL":
      return "nl";
    default:
      return "fr";
  }
};

const updateLocaleStorage = (country) => {
  switch (country) {
    case "US":
      localStorage.setItem("locale", "en");
      break;
    case "NL":
      localStorage.setItem("locale", "nl");
      break;
    default:
      localStorage.setItem("locale", "fr");
      break;
  }
};

const getCountryCode = () => {
  const locale = localStorage.getItem("locale");

  switch (locale) {
    case "en":
      return "US";
    case "nl":
      return "NL";
    default:
      return "FR";
  }
};

const EventLandingPage = () => {
  const [selected, setSelected] = useState(getCountryCode());
  const locale = getLocaleFromCountry(selected);

  useEffect(() => {
    updateLocaleStorage(selected);
  }, [selected]);

  return (
    <>
      <AnimationRevealPage>
        <Hero locale={locale} selected={selected} setSelected={setSelected} />
        <Features locale={locale} />
        <ContactUsForm locale={locale} />
        <Footer locale={locale} />
      </AnimationRevealPage>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default EventLandingPage;
