import React from "react";
import ReactDOM from "react-dom";
import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import Modal from "react-modal";
import EventLandingPage from "demos/EventLandingPage";
import * as serviceWorker from "./serviceWorker";

Modal.setAppElement("#root");

ReactDOM.render(<EventLandingPage />, document.getElementById("root"));

serviceWorker.register();
